import React, {useEffect, useState,} from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { useTelegram } from '../../TelegramContext';
import '../../assets/css/balance_nfs.css'
import {PetrolRedIcon} from "../../components/icons/PetrolRedIcon";
import {PetrolWhiteIcon} from "../../components/icons/PetrolWhiteIcon";
import {SettingsIcon} from "../../components/icons/SettingsIcon";
import {ReplenishIcon} from "../../components/icons/ReplenishIcon";
import {MoneyIcon} from "../../components/icons/MoneyIcon";
import {WalletIcon} from "../../components/icons/WalletIcon";
import {LogoIcon} from "../../components/icons/LogoIcon";
import {CloseBalanceIcon} from "../../components/icons/CloseBalanceIcon";
import {BalanceMainIcon} from "../../components/icons/BalanceMainIcon";
import {BalancePetrol} from "../../components/icons/BalancePetrol";
import  BuyPackageModal from "../../components/BuyPackageModal";
import useNFSPackages from "../../hooks/useNFSPackages";
import useUserData from "../../hooks/useUserData";
import GazAmount from "../../components/GazAmount";
import LoaderComponent from "../../components/Loader";
import {InputNfsIcon2} from "../../components/icons/InputNfsIcon2";

import PayTypeModal from "../../components/PayTypeModal";
import useTranslation from "../../hooks/useTranslation";

function BalanceNfsPage () {
    const { user, triggerVibration } = useTelegram();
    const { language, translation, handleLanguageChange } = useTranslation(); // Используем хук

    const {nfsBalance, userData, loading, error } = useUserData();
    const { NFSPackages, loadingNFSPackages, errorNFSPackages, fetchNFSPackages } = useNFSPackages();

    const [p2pFilterActiveItem, setP2pFilterActiveItem] = useState('all'); // all, mos_sdelki, prodazhi
    const [isActivefilterTop, setIsActivefilterTop] = useState(false);

    const [showBuyPackageModal, setShowBuyPackageModal] = useState(false);
    const [buyPackageData, setBuyPackageData] = useState(null);
    const [myOrders, setMyOrders] = useState([
        {walet: "UqBo...TKan", prodazha: "100000", pribil: 10000},
        {walet: "UqBo...TKan", prodazha: "100000", pribil: 10000},
        {walet: "UqBo...TKan", prodazha: "100000", pribil: 10000},
        {walet: "UqBo...TKan", prodazha: "100000", pribil: 10000},
        {walet: "UqBo...TKan", prodazha: "100000", pribil: 10000},
    ]);

    const [payData, setPayData] = useState(null);
    const [showPayTypeModal, setShowPayTypeModal] = useState(false);


    useEffect(() => {
        console.log(NFSPackages, 'NFSPackages')
    }, [NFSPackages]);

    const navigate = useNavigate();

    const showPackageModal = (item, balanceList_) => {
        setShowBuyPackageModal(true)
        setBuyPackageData({
            id: item?.id,
            modal_icon: item?.balance_icon,
            modal_title: item?.nfsAmount,
            modal_info: item?.tonAmount,
            modal_extra_info: balanceList_?.extra_info,
            modal_petrol_info: balanceList_?.petrol_info,
            modal_garage_info: balanceList_?.garage_number,
            balance_icon: balanceList_?.balance_icon,
        })
    }



    return (
        <>
            {( loading || loadingNFSPackages ) && (
                <LoaderComponent/>
            )}
            <div className="balance_nfs_page_wrapper p2pbg" id='balance_nfs_page'>
                <div className='general_page_header'>
                    <div className='general_page_item'>
                        <div className="general_page_item_top_child">
                            <Link
                                onClick={()=>{
                                    triggerVibration('heavy')
                                }}
                                to={'/settings'}
                                className="general_page_item_top_child_icon"
                                id='general_page_item_top_child_icon1'
                            >
                                <SettingsIcon/>
                            </Link>
                            <GazAmount userData={userData}/>


                        </div>

                    </div>
                    <div className="general_page_item">
                        <Link
                            onClick={()=>{
                                triggerVibration('heavy')
                            }}
                            to={'/'}
                            className="general_page_item_logo"

                        >
                            <div className="general_page_item_logo_box">
                                <LogoIcon/>
                            </div>

                            <div className="general_page_item_logo_img">
                                <img src={require('../../assets/images/logo.png')} alt=""/>
                            </div>
                        </Link>


                    </div>
                    <div className="general_page_item">
                        <div className="general_page_item_top_child">
                            <div className="general_page_item_top_child_title_icon_wrapper">
                                <MoneyIcon/>
                                <p className='general_page_item_top_child_title'>{nfsBalance}</p>
                            </div>
                            <Link
                                onClick={()=>{
                                    triggerVibration('heavy')
                                }}
                                to={'/wallet'}
                                className='general_page_item_top_child_icon'
                                id='general_page_item_top_child_icon2'
                            >
                                <WalletIcon/>
                            </Link>
                        </div>
                    </div>

                </div>
                <div className="balance_main_wrapper">


                    <div className={'p2p-wrapper'}>
                        <div className={'p2p-top-info'}>

                            <div className={'p2p-top-info-title'}>
                                p2p
                            </div>

                            <div className={'p2p-top-info-item green'}>
                                <div className={'p2p-top-info-item-left'}>
                                    Airdrop баланс:
                                </div>

                                <div className={'p2p-top-info-item-right'}>
                                    <span>1000000</span>
                                    <img src={require('../../assets/p2p/greenNfs.png')} alt=""/>
                                </div>

                            </div>
                            <div className={'p2p-top-info-item red'}>
                                <div className={'p2p-top-info-item-left'}>
                                    <div
                                        className={'p2p-top-info-item-left-info-img-wrapper'}
                                        onClick={()=>{

                                        }}
                                    >
                                        <img src={require('../../assets/p2p/info.png')} alt=""/>
                                    </div>
                                    Доступно для вывода:
                                </div>

                                <div className={'p2p-top-info-item-right'}>
                                    <span>100000</span>
                                    <img src={require('../../assets/p2p/redNfs.png')} alt=""/>
                                </div>

                            </div>
                            <div className={'p2p-top-info-item blue'}>
                                <div className={'p2p-top-info-item-left'}>
                                    Баланс TON:
                                </div>

                                <div className={'p2p-top-info-item-right'}>
                                    <span>0</span>
                                    <img src={require('../../assets/p2p/ton.png')} alt=""/>
                                </div>

                            </div>
                        </div>


                        <div className={'p2p-filter-main-wrapper'}>

                            <div className={'p2p-filter-wrapper'}>

                                <div onClick={() => {
                                    setP2pFilterActiveItem('all')
                                }} className={`p2p-filter-item ${p2pFilterActiveItem == 'all' && 'active'}`}>ВСЕ
                                </div>

                                <div onClick={() => {
                                    setP2pFilterActiveItem('mos_sdelki');
                                }} className={`p2p-filter-item ${p2pFilterActiveItem == 'mos_sdelki' && 'active'}`}>МОИ
                                    СДЕЛКИ
                                </div>

                            </div>


                            <div className={'p2p-cart-btn'}>
                                <img src={require('../../assets/p2p/cart.png')} alt=""/>
                            </div>

                            <button className={'p2p-filter-btn'} onClick={() => {
                                setIsActivefilterTop(prevState => !prevState)
                            }}>
                                <img className={`${isActivefilterTop ? 'reverse' : ""}`} src={require('../../assets/p2p/logo/sort.png')} alt=""/>
                            </button>


                            <div className={'p2p-filter-btn'}>
                                <img src={require('../../assets/p2p/history.png')} alt=""/>
                            </div>

                        </div>


                        {p2pFilterActiveItem == 'mos_sdelki' ?

                            <>
                                {myOrders.length == 0 ?

                                    <div className={'p2p-myorders-wrapper empty'}>

                                        <span>Здесь будут ваши текущие <br/> актуальные ордеры</span>

                                    </div>

                                    :
                                    <div className={'p2p-myorders-wrapper'}>

                                        {myOrders.map((item, index) => {
                                            return (
                                                <div className={'p2p-myorders-item'}>

                                                    <div className={'p2p-myorders-item-left'}>
                                                        <span>Name</span>
                                                        <span>{item?.walet}</span>
                                                    </div>

                                                    <div className={'p2p-myorders-item-right'}>

                                                        <div className={'p2p-myorders-item-right-left'}>

                                                            <div className={'p2p-myorders-item-right-top'}>
                                                                <span>Продажа</span>
                                                                <img src={require('../../assets/p2p/nfs2.png')} alt=""/>
                                                            </div>

                                                            <div className={'p2p-myorders-item-right-bottom'}>
                                                                <span>{item?.prodazha}</span>
                                                            </div>

                                                        </div>
                                                        <div className={'p2p-myorders-item-right-right'}>
                                                            <div className={'p2p-myorders-item-right-top'}>
                                                                <span>Прибыль</span>
                                                                <img src={require('../../assets/p2p/ton2.png')} alt=""/>
                                                            </div>

                                                            <div className={'p2p-myorders-item-right-bottom'}>
                                                                <span>{item?.pribil}</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        })}


                                    </div>

                                }

                            </>
                            :
                            <div className={'p2p-list-wrapper'}>

                                <div className="p2p-list-item">
                                    <div className="p2p-list-item-nfs">
                                        <img src={require('../../assets/p2p/logo/nfs.png')} alt=""/>
                                        <div className="p2p-list-item-value">
                                            839
                                        </div>
                                    </div>

                                    <div className="p2p-list-item-ton">
                                        <img src={require('../../assets/p2p/logo/ton.png')} alt=""/>
                                        <div className="p2p-list-item-value">
                                            0.036
                                        </div>
                                    </div>

                                    <div className="p2p-list-item-info">
                                        <div className="p2p-list-item-info-item">
                                            <span>0.0000048</span>
                                            <span>TON/NFS</span>
                                        </div>

                                        <div className="p2p-list-item-info-item">
                                            <span>Name</span>
                                            <span>UqBoTKan...</span>
                                        </div>
                                    </div>

                                    <button>
                                        Купить
                                    </button>
                                </div>

                                <div className="p2p-list-item">
                                    <div className="p2p-list-item-nfs">
                                        <img src={require('../../assets/p2p/logo/nfs.png')} alt=""/>
                                        <div className="p2p-list-item-value">
                                            839
                                        </div>
                                    </div>

                                    <div className="p2p-list-item-ton">
                                        <img src={require('../../assets/p2p/logo/ton.png')} alt=""/>
                                        <div className="p2p-list-item-value">
                                            0.036
                                        </div>
                                    </div>

                                    <div className="p2p-list-item-info">
                                        <div className="p2p-list-item-info-item">
                                            <span>0.0000048</span>
                                            <span>TON/NFS</span>
                                        </div>

                                        <div className="p2p-list-item-info-item">
                                            <span>Name</span>
                                            <span>UqBoTKan...</span>
                                        </div>
                                    </div>

                                    <button>
                                        Купить
                                    </button>
                                </div>

                                <div className="p2p-list-item">
                                    <div className="p2p-list-item-nfs">
                                        <img src={require('../../assets/p2p/logo/nfs.png')} alt=""/>
                                        <div className="p2p-list-item-value">
                                            839
                                        </div>
                                    </div>

                                    <div className="p2p-list-item-ton">
                                        <img src={require('../../assets/p2p/logo/ton.png')} alt=""/>
                                        <div className="p2p-list-item-value">
                                            0.036
                                        </div>
                                    </div>

                                    <div className="p2p-list-item-info">
                                        <div className="p2p-list-item-info-item">
                                            <span>0.0000048</span>
                                            <span>TON/NFS</span>
                                        </div>

                                        <div className="p2p-list-item-info-item">
                                            <span>Name</span>
                                            <span>UqBoTKan...</span>
                                        </div>
                                    </div>

                                    <button>
                                        Купить
                                    </button>
                                </div>

                                <div className="p2p-list-item">
                                    <div className="p2p-list-item-nfs">
                                        <img src={require('../../assets/p2p/logo/nfs.png')} alt=""/>
                                        <div className="p2p-list-item-value">
                                            839
                                        </div>
                                    </div>

                                    <div className="p2p-list-item-ton">
                                        <img src={require('../../assets/p2p/logo/ton.png')} alt=""/>
                                        <div className="p2p-list-item-value">
                                            0.036
                                        </div>
                                    </div>

                                    <div className="p2p-list-item-info">
                                        <div className="p2p-list-item-info-item">
                                            <span>0.0000048</span>
                                            <span>TON/NFS</span>
                                        </div>

                                        <div className="p2p-list-item-info-item">
                                            <span>Name</span>
                                            <span>UqBoTKan...</span>
                                        </div>
                                    </div>

                                    <button>
                                        Купить
                                    </button>
                                </div>


                            </div>

                        }

                    </div>

                    <Link
                        onClick={() => {
                            triggerVibration('heavy')
                        }}
                        to={'/general'}
                        className='balance_btn'
                    >
                        {translation?.back_to_home}
                        {/*<img src={require('../../assets/images/balance_btn_img.png')} alt=""/>*/}
                    </Link>
                </div>

                <BuyPackageModal
                    isActive={showBuyPackageModal}
                    onClose={() => {
                        setShowBuyPackageModal(false)
                    }}
                    payClose={(data) => {
                        setPayData(data)
                        setShowBuyPackageModal(false)
                        setShowPayTypeModal(true)
                    }}
                    packageData={buyPackageData}
                />


                {showPayTypeModal &&
                    <PayTypeModal
                        data={payData}
                        isActive={true}
                        onClose={() => {
                            setShowPayTypeModal(false)
                        }}
                    />
                }
            </div>
        </>

    );
}

export default BalanceNfsPage;

