import React, {useEffect, useRef, useState} from 'react';
import { useTelegram } from '../../TelegramContext';
import '../../assets/css/general.css'
import {PetrolRedIcon} from "../../components/icons/PetrolRedIcon";
import {PetrolWhiteIcon} from "../../components/icons/PetrolWhiteIcon";
import {SettingsIcon} from "../../components/icons/SettingsIcon";
import {ReplenishIcon} from "../../components/icons/ReplenishIcon";
import {MoneyIcon} from "../../components/icons/MoneyIcon";
import {WalletIcon} from "../../components/icons/WalletIcon";
import {ProfileIcon} from "../../components/icons/ProfileIcon";
import {RatingIcon} from "../../components/icons/RatingIcon";
import {FriendsIcon} from "../../components/icons/FriendsIcon";
import {P2PIcon} from "../../components/icons/P2PIcon";
import {CarDealershipIcon} from "../../components/icons/CarDealershipIcon";
import {GarageIcon} from "../../components/icons/GarageIcon";
import {CarMarketIcon} from "../../components/icons/CarMarketIcon";
import {ExhibitionIcon} from "../../components/icons/ExhibitionIcon";
import {DailyRewardIcon} from "../../components/icons/DailyRewardIcon";
import {LogoIcon} from "../../components/icons/LogoIcon";
import {LockIcon} from "../../components/icons/LockIcon";
import {LeftIcon} from "../../components/icons/LeftIcon";
import {useNavigate, Link} from "react-router-dom";
import useUserData from "../../hooks/useUserData";
// import Loader from "../../components/Loader";
import GazAmount from "../../components/GazAmount";
import LoaderComponent from "../../components/Loader";
import AudioPlayer from "../../components/AudioPlayer";
import useTranslation from "../../hooks/useTranslation";


function GeneralPage () {

    const { language, translation, handleLanguageChange } = useTranslation(); // Используем хук

    const { user, triggerVibration } = useTelegram();
    const {nfsBalance, userData, loading, error } = useUserData();

    const [windowHeight, setWindowHeight] = useState(window.innerHeight);

    const [showCarType, setShowCarType] = useState('free');
    const [carImgUrl, setCarImgUrl] = useState('');
    const [carClass, setCarClass] = useState('');

    useEffect(() => {

        if (translation) {
            console.log(translation, 'translation222')
        }
    }, [translation]);

    useEffect(() => {
        const handleResize = () => {
            setWindowHeight(window.innerHeight);
        };

        window.addEventListener('resize', handleResize);

        // Cleanup the event listener on component unmount
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const getHeightClassForFreeCar = () => {
        if (windowHeight >= 538 && windowHeight <= 638) {
            return 'general_page_car_img1';
        } else  if (windowHeight >= 510 && windowHeight <= 538) {
            return 'general_page_car_img2';
        }  else  if (windowHeight <= 510) {
            return 'general_page_car_img3';
        }
    };

    const getHeightClassForRound3Car = () => {
        if (windowHeight >= 538 && windowHeight <= 638) {
            return 'general_page_car_img1';
        } else  if (windowHeight >= 510 && windowHeight <= 538) {
            return 'general_page_car_img2';
        }  else  if (windowHeight <= 510) {
            return 'general_page_car_img3';
        }
    };
    const getHeightClassForRound4Car = () => {
        if (windowHeight >= 538 && windowHeight <= 638) {
            return 'general_page_car_img1';
        } else  if (windowHeight >= 510 && windowHeight <= 538) {
            return 'general_page_car_img2';
        }  else  if (windowHeight <= 510) {
            return 'general_page_car_img3';
        }
    };
    const getHeightClassForRound1Car = () => {
        if (windowHeight >= 538 && windowHeight <= 638) {
            return 'general_page_car_img1';
        } else  if (windowHeight >= 510 && windowHeight <= 538) {
            return 'general_page_car_img2';
        }  else  if (windowHeight <= 510) {
            return 'general_page_car_img3';
        }
    };
    const getHeightClassForRound2Car = () => {
        if (windowHeight >= 538 && windowHeight <= 638) {
            return 'general_page_car_img1';
        } else  if (windowHeight >= 510 && windowHeight <= 538) {
            return 'general_page_car_img2';
        }  else  if (windowHeight <= 510) {
            return 'general_page_car_img3';
        }

    };




    useEffect(() => {
        console.log(user, 'user')
        getCarData();
    }, []);


    const getCarData = () => {


        setCarImgUrl(`${process.env.REACT_APP_DOMAIN}/${userData?.car?.imagePath}`)
        setShowCarType('free')
        setCarClass('general_page_car_img_free')
    }

    const navigate = useNavigate();



    return (
        <>
            {( loading ) && (
                <LoaderComponent/>
            )}

            {/*<AudioPlayer/>*/}

            <div className="general_page_wrapper" id='general_page'>
                <div className='general_page_header'>
                    <div className='general_page_item'>
                        <div className="general_page_item_top_child">
                            <Link
                                onClick={()=>{
                                    triggerVibration('heavy')
                                }}
                                to={'/settings'}
                                className="general_page_item_top_child_icon"
                                id='general_page_item_top_child_icon1'
                            >
                                <SettingsIcon/>
                            </Link>

                            <GazAmount
                                userData={userData}
                            />

                        </div>

                    </div>
                    <div className="general_page_item">
                        <Link
                            to={'/'}
                            onClick={()=>{
                                triggerVibration('heavy')
                            }}
                            className="general_page_item_logo"
                        >
                            <div className="general_page_item_logo_box">
                                <LogoIcon/>
                            </div>

                            <div className="general_page_item_logo_img">
                                <img src={require('../../assets/images/logo.png')} alt=""/>
                            </div>
                        </Link>


                    </div>
                    <div className="general_page_item">
                        <div className="general_page_item_top_child">
                            <Link
                                onClick={()=>{
                                    triggerVibration('heavy')
                                }}
                                to={'/balanceNfs'}
                                className="general_page_item_top_child_title_icon_wrapper"
                            >
                                <MoneyIcon/>
                                <p className='general_page_item_top_child_title'>{nfsBalance}</p>
                            </Link>
                            <Link
                                onClick={()=>{
                                    triggerVibration('heavy')
                                }}
                                to={'/wallet'}
                                className='general_page_item_top_child_icon'
                                id='general_page_item_top_child_icon2'
                            >
                                <WalletIcon/>
                            </Link>
                        </div>
                    </div>

                </div>
                <div className="general_page_items_wrapper">

                    <div className="general_page_item2" id='general_page_item_left'>

                        <Link
                            onClick={()=>{
                                triggerVibration('heavy')
                            }}
                            to={'/profile'}
                            className="general_page_item_child"
                        >
                            <div className="general_page_item_child_icon">
                                <ProfileIcon/>
                            </div>
                            <p className='general_page_item_child_title'>{translation?.profile}</p>
                        </Link>

                        <Link
                            onClick={()=>{
                                triggerVibration('heavy')
                            }}
                            // to={'/rating'}
                            className="general_page_item_child"
                        >
                            <div className="general_page_item_child_icon">
                                {/*<RatingIcon/>*/}
                                <div className="general_page_item_child_icon_child">
                                    <img src={require('../../assets/images/lock_img.png')} alt=""/>
                                </div>
                            </div>
                            <p className='general_page_item_child_title'>{translation?.rating}</p>
                        </Link>

                        <Link
                            onClick={()=>{
                                triggerVibration('heavy')
                            }}
                            to={'/friends'}
                            className="general_page_item_child"
                        >
                            <div className="general_page_item_child_icon">
                                <FriendsIcon/>

                            </div>
                            <p className='general_page_item_child_title'>{translation?.frens}</p>
                        </Link>

                        <Link
                            onClick={()=>{
                                triggerVibration('heavy')
                            }}
                            // to={'/p2p'}
                            className="general_page_item_child"
                        >
                            <div className="general_page_item_child_icon">
                                {/*<LockIcon/>*/}
                                {/*<P2PIcon />*/}

                                <div className="general_page_item_child_icon_child">
                                    <img src={require('../../assets/images/lock_img.png')} alt=""/>
                                </div>
                            </div>
                            <p className='general_page_item_child_title'>{translation?.p2p}</p>
                        </Link>
                        <div className='general_page_item_help_btn_wrapper'>
                            <Link
                                onClick={()=>{
                                    triggerVibration('heavy')
                                }}
                                to={'/infoAboutProject'}
                                className='general_page_item_help_btn'
                            >
                                {language == 'ENG' ?

                                    <>
                                        <span className='general_page_item_help_btn_text'>H</span>
                                        <span className='general_page_item_help_btn_text'>E</span>
                                        <span className='general_page_item_help_btn_text'>L</span>
                                        <span className='general_page_item_help_btn_text'>P</span>
                                    </>

                                    :

                                    <>
                                        <span className='general_page_item_help_btn_text'>П</span>
                                        <span className='general_page_item_help_btn_text'>О</span>
                                        <span className='general_page_item_help_btn_text'>М</span>
                                        <span className='general_page_item_help_btn_text'>О</span>
                                        <span className='general_page_item_help_btn_text'>Щ</span>
                                        <span className='general_page_item_help_btn_text'>Ь</span>
                                    </>
                                }


                            </Link>
                        </div>

                    </div>


                    <div className="general_page_item2" id='general_page_item_right'>
                        <Link
                            onClick={() => {
                                triggerVibration('heavy')
                            }}
                            to={'/carDealerShip'}
                            className="general_page_item_child"
                        >
                            <p className='general_page_item_child_title' style={{width:55}}>{translation?.car_dealership}</p>
                            <div className="general_page_item_child_icon">
                                <CarDealershipIcon/>
                            </div>
                        </Link>

                        <Link
                            onClick={()=>{
                                triggerVibration('heavy')
                            }}
                            className='general_page_item_child'
                            to="/garage"
                        >
                            <p className='general_page_item_child_title'>{translation?.garage}</p>
                            <div className="general_page_item_child_icon">
                                <GarageIcon/>
                            </div>
                        </Link>


                        <Link
                            onClick={()=>{
                                triggerVibration('heavy')
                            }}
                            // to="/carMarket"
                            className="general_page_item_child"
                        >
                            <p className='general_page_item_child_title'>{translation?.car_market}</p>
                            <div className="general_page_item_child_icon">
                                {/*<CarMarketIcon/>*/}
                                <div className="general_page_item_child_icon_child">
                                    <img src={require('../../assets/images/lock_img.png')} alt=""/>
                                </div>
                            </div>
                        </Link>

                        <Link
                            onClick={()=>{
                                triggerVibration('heavy')
                            }}
                            to={'/carExhibition'}
                            className="general_page_item_child"
                        >
                            <p className='general_page_item_child_title'>{translation?.exhibition}</p>
                            <div className="general_page_item_child_icon">
                                <ExhibitionIcon/>

                            </div>
                        </Link>

                        <div className="general_page_item_child_wheel_icon_wrapper">
                            <Link
                                onClick={()=>{
                                    triggerVibration('heavy')
                                }}
                                to={'/roulette'}
                                className='general_page_item_child_wheel_icon'
                            >
                                <img src={require('../../assets/images/wheel_icon3.png')} alt=""/>
                            </Link>
                        </div>

                        <Link
                            onClick={()=>{
                                triggerVibration('heavy')
                            }}
                            to={'/dailyReward'}
                            className='general_page_item_child_daily_reward_icon'
                        >
                            <DailyRewardIcon/>
                        </Link>

                    </div>
                </div>
                <div className={
                    carClass === 'general_page_car_img_free'
                        ? `${carClass} ${getHeightClassForFreeCar()}`
                        : carClass === 'general_page_car_img_round1'
                            ? `${carClass} ${getHeightClassForRound1Car()}`
                            : carClass === 'general_page_car_img_round2'
                                ? `${carClass} ${getHeightClassForRound2Car()}`
                            : carClass === 'general_page_car_img_round3'
                                ? `${carClass} ${getHeightClassForRound3Car()}`
                            : carClass === 'general_page_car_img_round4'
                                ? `${carClass} ${getHeightClassForRound4Car()}`
                            : ''
                }>
                    <img src={`${process.env.REACT_APP_IMG_DOMAIN}/${userData?.car?.imagePath}`} alt=""/>
                </div>

                <div className='general_page_footer'>
                    <Link
                        onClick={()=>{
                            triggerVibration('heavy')
                        }}
                        className='general_page_footer_btn'
                        to="/game"
                    >
                        {translation?.play}
                    </Link>
                </div>



            </div>
        </>

    );
}

export default GeneralPage;

