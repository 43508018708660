import React, {useEffect, useState,} from 'react';
import {Link, useNavigate} from 'react-router-dom';
import { useTelegram } from '../../TelegramContext';
import '../../assets/css/game_fight.css'
import {PetrolRedIcon} from "../../components/icons/PetrolRedIcon";
import {PetrolWhiteIcon} from "../../components/icons/PetrolWhiteIcon";
import {SettingsIcon} from "../../components/icons/SettingsIcon";
import {ReplenishIcon} from "../../components/icons/ReplenishIcon";
import {MoneyIcon} from "../../components/icons/MoneyIcon";
import {WalletIcon} from "../../components/icons/WalletIcon";
import {LogoIcon} from "../../components/icons/LogoIcon";
import {CloseBalanceIcon} from "../../components/icons/CloseBalanceIcon";
import {LoaderLogoIcon} from "../../components/icons/LoaderLogoIcon";
import useUserData from "../../hooks/useUserData";
import GazAmount from "../../components/GazAmount";
import useFindRival from "../../hooks/useFindRival";
import LoaderComponent from "../../components/Loader";
import usePlayGame from "../../hooks/usePlayGame";
import NoGasModal from "../../components/NoGasModal";
import useTranslation from "../../hooks/useTranslation";


function GameFightPage () {
    const { user, webApp } = useTelegram();
    const {translation} = useTranslation();

    const { nfsBalance, userData, loading, error, fetchUserData } = useUserData();
    const { findRival, loadingFindRival, errorFindRival, fetchFindRival } = useFindRival();
    const { playResult, loadingPlayResult, errorPlayResult, fetchPlayResult } = usePlayGame();

    const [showLoader, setShowLoader] = useState(false);
    const [showWonPopup, setShowWonPopup] = useState(false);
    const [showLosePopup, setShowLosePopup] = useState(false);

    const [showNoGasModal, setShowNoGasModal] = useState(false);



    useEffect(() => {

        if (errorFindRival) {
            if (!errorFindRival?.enough_gas) {
                setShowNoGasModal(true)
            } else {
                setShowNoGasModal(false)
            }
            console.log(errorFindRival, 'errorFindRival')
        }
    }, [errorFindRival]);

    useEffect(() => {
        setShowWonPopup(false);
        setShowLosePopup(false);
    }, []);


    useEffect(() => {
        if (findRival) {
            if (!findRival?.enough_gas) {
                setShowNoGasModal(true)
            } else {
                setShowNoGasModal(false)
            }
        }

    }, [findRival]);



    useEffect(() => {
        if (playResult) {
            if (playResult?.is_win) {
                setShowWonPopup(true);
                setShowLosePopup(false);
            } else {
                setShowWonPopup(false);
                setShowLosePopup(true);
            }
        }

        console.log(playResult, 'playResult')
    }, [playResult]);

    const navigate = useNavigate();


    return (
        <>
            {( loading || loadingFindRival || loadingPlayResult ) && (
                <LoaderComponent/>
            )}
            <div className="game_fight_page_wrapper" id='game_fight_page'>
                <div className='general_page_header'>
                    <div className='general_page_item'>
                        <div className="general_page_item_top_child">
                            <Link
                                to={'/settings'}
                                className="general_page_item_top_child_icon"
                                id='general_page_item_top_child_icon1'
                            >
                                <SettingsIcon/>
                            </Link>
                            <GazAmount userData={userData}/>

                        </div>

                    </div>
                    <div className="general_page_item">
                        <div className="general_page_item_logo">
                            <Link to={'/'} className="general_page_item_logo_box">
                                <LogoIcon/>
                            </Link>

                            <div className="general_page_item_logo_img">
                                <img src={require('../../assets/images/logo.png')} alt=""/>
                            </div>
                        </div>


                    </div>
                    <div className="general_page_item">
                        <div className="general_page_item_top_child">
                            <Link
                                to={'/balanceNfs'}
                                className="general_page_item_top_child_title_icon_wrapper"
                            >
                                <MoneyIcon/>
                                <p className='general_page_item_top_child_title'>{nfsBalance}</p>
                            </Link>
                            <Link
                                to={'/wallet'}
                                className='general_page_item_top_child_icon'
                                id='general_page_item_top_child_icon2'
                            >
                                <WalletIcon/>
                            </Link>
                        </div>
                    </div>

                </div>
                <div className="game_page_main_wrapper">
                    <div className="game_page_main_wrapper_child">
                          <button
                              className='game_page_main_wrapper_child_close_btn'
                              onClick={() => {
                                  navigate(-1)
                              }}
                          >
                              <CloseBalanceIcon/>
                          </button>
                        <div className='game_page_main_wrapper_child_title_box'>
                            <p className='game_page_main_wrapper_child_title'>{translation?.fight}</p>
                        </div>
                          <div className='gamers_items_wrapper'>
                              <div className="gamers_item">
                                    <div className="gamers_item_car_info_details_wrapper">
                                        <div className="gamers_item_car_info_details_info_item">
                                            <h1 className='gamers_item_car_info_details_info_item_car_name'>{userData?.car?.name}</h1>
                                            <div className='gamers_item_car_info_details_info_item_childs_wrapper'>
                                                <div className='gamers_item_car_info_details_info_item_child1'>
                                                    <p className='gamers_item_car_info_details_info_item_child_info'>Stage {userData?.car?.stage}</p>
                                                </div>
                                                <div className='gamers_item_car_info_details_info_item_child2'>
                                                    <p className='gamers_item_car_info_details_info_item_child_info'>{userData?.car?.horsePower} HP</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="gamers_item_car_info_details_img_item">
                                            {/*<img src={require('../../assets/images/game_fight_car_img.png')} alt=""/>*/}
                                            <img src={`${process.env.REACT_APP_IMG_DOMAIN}/${userData?.car?.imagePath}`}
                                                alt=""/>

                                        </div>
                                    </div>
                                  <div className='gamers_item_user_info_img_wrapper'>
                                  <div className='gamers_item_user_img'>
                                             <img src={`${process.env.REACT_APP_IMG_DOMAIN}/${userData?.avatarPath}`}
                                                 alt=""
                                             />
                                         </div>
                                         <p className='gamers_item_user_name'>{userData?.name}</p>
                                     </div>
                              </div>
                              <div className='vs_box'>
                                  <p className='vs_title'>VS</p>
                              </div>
                              <div className="gamers_item">
                                    <div className="gamers_item_car_info_details_wrapper">
                                        <div className="gamers_item_car_info_details_info_item">
                                            <h1 className='gamers_item_car_info_details_info_item_car_name'>{findRival?.rival_info?.car?.name}</h1>
                                            <div className='gamers_item_car_info_details_info_item_childs_wrapper'>
                                                <div className='gamers_item_car_info_details_info_item_child1'>
                                                    <p className='gamers_item_car_info_details_info_item_child_info'>Stage {findRival?.rival_info?.car?.stage}</p>
                                                </div>
                                                <div className='gamers_item_car_info_details_info_item_child2'>
                                                    <p className='gamers_item_car_info_details_info_item_child_info'>{findRival?.rival_info?.car?.horse_power} HP</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="gamers_item_car_info_details_img_item">
                                            <img src={`${process.env.REACT_APP_IMG_DOMAIN}/${findRival?.rival_info?.car?.image_path}`} alt=""/>


                                        </div>
                                    </div>
                                     <div className='gamers_item_user_info_img_wrapper'>
                                         <div className='gamers_item_user_img'>
                                             <img src={`${process.env.REACT_APP_IMG_DOMAIN}/${findRival?.rival_info?.user?.avatar_path}`}
                                                 alt=""/>

                                         </div>
                                         <p className='gamers_item_user_name'>{findRival?.rival_info?.user?.first_name}</p>
                                     </div>
                              </div>
                              <div className='game_fight_page_info_box'>
                                  <p className='game_fight_page_info_box_info'>
                                      {translation?.text22}
                                  </p>
                              </div>
                          </div>

                          <div className='game_fight_footer'>
                              <button onClick={fetchFindRival} className='game_fight_footer_btn1'>
                                  {translation?.search_for_player}
                                  {/*<img src={require('../../assets/images/search_game_btn_img.png')} alt=""/>*/}
                              </button>
                              {!errorFindRival &&
                                  <button
                                      className='game_fight_footer_btn2'
                                      onClick={() => {

                                          fetchPlayResult()

                                          // setShowLoader(true)
                                          // setTimeout(() => {
                                          //     setShowLoader(false)
                                          //     setShowWonPopup(true)
                                          // }, 3000)

                                      }}
                                  >{translation?.play}</button>
                              }

                          </div>


                    </div>
                </div>

                {showLoader &&
                    <div className='loader_parent'>
                        <div className="loader_parent_wrapper">
                            <div className='loader_parent_logo'>
                                <LoaderLogoIcon/>
                            </div>
                            <div className="loader_box">
                                <div className="loader_box_child"></div>
                            </div>
                        </div>
                    </div>
                }

                {showWonPopup &&
                    <div className='show_won_popup'>
                        <div className='show_won_popup_wrapper'>
                            <Link
                                to={'/game'}
                                className="popup_close_btn"
                                onClick={() => {
                                    setShowWonPopup(false)
                                    setShowLosePopup(false)
                                }}
                            >
                                <CloseBalanceIcon/>
                            </Link>
                            <div className="show_won_popup_icon">
                                <img src={require('../../assets/images/won_img.png')} alt=""/>
                            </div>
                            <h1 className='show_won_popup_title'>{translation?.you_won}</h1>
                            <p className='show_won_popup_info'>
                                {translation?.you_have_won}
                                .
                                .
                                .
                                .
                                .
                                .
                                .
                                .
                                .
                                .
                                .
                                .
                                .
                                .
                                .
                                .
                                .
                                .
                                .
                                .
                                {playResult?.xp} XP {'\n'}
                                {playResult?.nfs} NFS
                            </p>
                            <button
                                className='show_won_popup_btn'
                                onClick={() => {
                                    setShowLosePopup(false);
                                    setShowWonPopup(false);
                                    fetchUserData()
                                    fetchFindRival();
                                }}
                            >
                                {translation?.continue}
                            </button>
                        </div>
                    </div>
                }
                {showLosePopup &&
                    <div className='show_won_popup'>
                        <div className='show_lose_popup_wrapper'>
                            <Link
                                to={'/game'}
                                className="popup_close_btn"
                                onClick={() => {
                                    setShowLosePopup(true)
                                    setShowWonPopup(false)
                                }}
                            >
                                <CloseBalanceIcon/>
                            </Link>
                            <div className="show_won_popup_icon">
                                <img src={require('../../assets/images/lose_img.png')} alt=""/>
                            </div>
                            <h1 className='show_lose_popup_title'>{translation?.you_lose}</h1>
                            <p className='show_won_popup_info'>
                                {translation?.you_have_lose}
                                .
                                .
                                .
                                .
                                .
                                .
                                .
                                .
                                .
                                .
                                .
                                .
                                .
                                .
                                .
                                .
                                .
                                .
                                .
                                .
                                .
                                .
                                .
                                .
                                .
                                10 XP
                                0 NFS
                            </p>
                            <button
                                onClick={()=>{
                                    setShowLosePopup(false);
                                    setShowWonPopup(false);
                                    fetchUserData()
                                    fetchFindRival();
                                }}
                                className='show_lose_popup_btn'
                            >
                                {translation?.continue}
                            </button>
                        </div>
                    </div>
                }

            </div>


            <NoGasModal
                isActive={showNoGasModal}
                onClose={() => {
                    setShowNoGasModal(false)
                    navigate('/')
                }}
                onCloseBuyGas={() => {
                    setShowNoGasModal(false)
                    navigate('/gusUp')
                }}
                // carData={carInfo}
            />
        </>

    );
}

export default GameFightPage;

