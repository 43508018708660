import React, { useEffect, useState } from 'react';
import { useTelegram } from '../../TelegramContext';
import '../../assets/css/garage.css';
import classNames from 'classnames';

import { PetrolRedIcon } from "../../components/icons/PetrolRedIcon";
import { PetrolWhiteIcon } from "../../components/icons/PetrolWhiteIcon";
import { SettingsIcon } from "../../components/icons/SettingsIcon";
import { ReplenishIcon } from "../../components/icons/ReplenishIcon";
import { MoneyIcon } from "../../components/icons/MoneyIcon";
import { WalletIcon } from "../../components/icons/WalletIcon";
import { ProfileIcon } from "../../components/icons/ProfileIcon";
import Inventory from "../../components/icons/Inventory";

import { RatingIcon } from "../../components/icons/RatingIcon";
import { FriendsIcon } from "../../components/icons/FriendsIcon";
import { P2PIcon } from "../../components/icons/P2PIcon";
import { CarDealershipIcon } from "../../components/icons/CarDealershipIcon";
import { GarageActiveIcon } from "../../components/icons/GarageActiveAcon";
import { CarMarketIcon } from "../../components/icons/CarMarketIcon";
import { ExhibitionIcon } from "../../components/icons/ExhibitionIcon";
import { LogoIcon } from "../../components/icons/LogoIcon";
import { LeftIcon } from "../../components/icons/LeftIcon";
import { RightIcon } from "../../components/icons/RightIcon";
import { SpotIcon } from "../../components/icons/SpotIcon";
import {CarsListIcon} from "../../components/icons/CarsListIcon";
import {GrayIcon} from "../../components/icons/GrayIcon";
import UpgradeStageModal from "../../components/UpgradeStageModal";
import {Link, useNavigate} from "react-router-dom";
import useGarageInfo from "../../hooks/useGarageInfo";
import useUpStage from "../../hooks/useUpStage";
import useUserData from "../../hooks/useUserData";
import GazAmount from "../../components/GazAmount";
import LoaderComponent from "../../components/Loader";
import useSelectCar from "../../hooks/useSelectCar";
import useTranslation from "../../hooks/useTranslation";
import SpaceListModal from "../../components/SpaceListModal";
import BySpaceModal from "../../components/BySpaceModal";
import NoNfsModal from "../../components/NoNfsModal";
import InventoryModal from "../../components/InventoryModal";


import { useLocation } from 'react-router-dom';
import useGarageUpstage from "../../hooks/useGarageUpstage";


function GaragePage () {

    const location = useLocation();

    const searchParams = new URLSearchParams(location.search);
    const openSlot = searchParams.get('openSlot');

    const { user, triggerVibration } = useTelegram();
    const {translation} = useTranslation();

    const [windowHeight, setWindowHeight] = useState(window.innerHeight);
    const [currentCarIndex, setCurrentCarIndex] = useState(0);
    const [isLoading, setIsLoading] = useState(false);

    const {garageInfo, loadingGarageInfo, errorGarageInfo, fetchGetGarageInfo} = useGarageInfo();
    const {upStageAnswer, loadingUpStage, errorUpStage, fetchUpStage} = useUpStage(false);
    const {activeCarId, nfsBalance, userData, loading, error } = useUserData();

    const {garageUpstage, errorGarageUpstage, loadingGarageUpstage, fetchGarageUpstage } = useGarageUpstage();

    const {selectCarData, loadingSelectCarData, errorSelectCarData, fetchSelectCar} = useSelectCar();

    const [auto1ActiveStage, setAuto1ActiveStage] = useState('0');
    const [auto2ActiveStage, setAuto2ActiveStage] = useState('1');
    const [auto3ActiveStage, setAuto3ActiveStage] = useState('2');
    const [auto4ActiveStage, setAuto4ActiveStage] = useState('3');

    const [showGarageList, setShowGarageList] = useState([
        [
            {
                id: 1,
                car_type: 'free',
                car_img_url: require('../../assets/images/free_car_img.png'),
                car_class: 'general_page_car_img_free',
                car_name: 'Nissan GT-R',
                car_hp: '200 hp',
                car_power: '20%'
            },
        ],
        [
            {
                id: 2,
                car_type: '1_1',
                car_img_url: require('../../assets/images/round1_img1.png'),
                car_class: 'general_page_car_img_round1',
                car_name: 'Nissan GT-R',
                car_hp: '400 hp',
                car_power: '40%'
            },
            {
                id: 3,
                car_type: '1_2',
                car_img_url: require('../../assets/images/round1_img2.png'),
                car_class: 'general_page_car_img_round1',
                car_name: 'Nissan GT-R',
                car_hp: '600 hp',
                car_power: '50%'
            },
            {
                id: 4,
                car_type: '1_3',
                car_img_url: require('../../assets/images/round1_img3.png'),
                car_class: 'general_page_car_img_round1',
                car_name: 'Nissan GT-R',
                car_hp: '600 hp',
                car_power: '50%'
            },
            {
                id: 5,
                car_type: '1_4',
                car_img_url: require('../../assets/images/round1_img3.png'),
                car_class: 'general_page_car_img_round1',
                car_name: 'Nissan GT-R',
                car_hp: '600 hp',
                car_power: '50%'
            },
        ],
        [
            {
                id: 6,
                car_type: '2_1',
                car_img_url: require('../../assets/images/round2_img1.png'),
                car_class: 'general_page_car_img_round2',
                car_name: 'Nissan GT-R',
                car_hp: '800 hp',
                car_power: '60%'
            },
            {
                id: 7,
                car_type: '2_2',
                car_img_url: require('../../assets/images/round2_img2.png'),
                car_class: 'general_page_car_img_round2',
                car_name: 'Nissan GT-R',
                car_hp: '900 hp',
                car_power: '70%'
            },
            {
                id: 8,
                car_type: '2_3',
                car_img_url: require('../../assets/images/round2_img3.png'),
                car_class: 'general_page_car_img_round2',
                car_name: 'Nissan GT-R',
                car_hp: '500 hp',
                car_power: '80%'
            },
            {
                id: 9,
                car_type: '2_4',
                car_img_url: require('../../assets/images/round2_img4.png'),
                car_class: 'general_page_car_img_round2',
                car_name: 'Nissan GT-R',
                car_hp: '700 hp',
                car_power: '90%'
            },
        ],
        [
            {
                id: 10,
                car_type: '3_1',
                car_img_url: require('../../assets/images/round3_img1.png'),
                car_class: 'general_page_car_img_round3',
                car_name: 'Nissan GT-R',
                car_hp: '400 hp',
                car_power: '40%'
            },
            {
                id: 11,
                car_type: '3_2',
                car_img_url: require('../../assets/images/round3_img1.png'),
                car_class: 'general_page_car_img_round3',
                car_name: 'Nissan GT-R',
                car_hp: '400 hp',
                car_power: '40%'
            },
            {
                id: 12,
                car_type: '3_3',
                car_img_url: require('../../assets/images/round3_img1.png'),
                car_class: 'general_page_car_img_round3',
                car_name: 'Nissan GT-R',
                car_hp: '400 hp',
                car_power: '40%'
            },
            {
                id: 13,
                car_type: '3_4',
                car_img_url: require('../../assets/images/round3_img1.png'),
                car_class: 'general_page_car_img_round3',
                car_name: 'Nissan GT-R',
                car_hp: '400 hp',
                car_power: '40%'
            },

        ],
        [
            {
                id: 14,
                car_type: '4_1',
                car_img_url: require('../../assets/images/round4_img1.png'),
                car_class: 'general_page_car_img_round4',
                car_name: 'Nissan GT-R',
                car_hp: '900 hp',
                car_power: '100%'
            },
            {
                id: 15,
                car_type: '4_2',
                car_img_url: require('../../assets/images/round4_img2.png'),
                car_class: 'general_page_car_img_round4',
                car_name: 'Nissan GT-R',
                car_hp: '200 hp',
                car_power: '30%'
            },
            {
                id: 16,
                car_type: '4_3',
                car_img_url: require('../../assets/images/round4_img3.png'),
                car_class: 'general_page_car_img_round4',
                car_name: 'Nissan GT-R',
                car_hp: '300 hp',
                car_power: '50%'
            },
            {
                id: 17,
                car_type: '4_4',
                car_img_url: require('../../assets/images/round4_img4.png'),
                car_class: 'general_page_car_img_round4',
                car_name: 'Nissan GT-R',
                car_hp: '550 hp',
                car_power: '60%'
            },
        ],
    ]);

    const [showChangeStageModal, setShowChangeStageModal] = useState(false);
    const [prevAndNextStageForStageModal, setPrevAndNextStageForStageModal] = useState(null);
    const [footerClass, setFooterClass] = useState('');

    const [showNfsModal , setShowNoNfsModal ] = useState(false);
    const [inventoryModal , setInventoryModal ] = useState(false);

    const [showSpaceListModal, setShowSpaceListModal] = useState(false);
    const [showBySpaceModal, setShowBySpaceModal] = useState(false);
    const [bySpaceModalType, setBySpaceModalType] = useState('1');


    useEffect(() => {

        if (openSlot) {
            setShowSpaceListModal(true)
            console.log(openSlot, 'openSlot')
        }

    }, [openSlot]);

    useEffect(() => {

        if (activeCarId && garageInfo) {

            let cars_ = garageInfo?.cars;
            let index = cars_.findIndex((car) => car.id == activeCarId);
            setCurrentCarIndex(index)
            console.log(index, 'index')
        }
    }, [activeCarId, garageInfo]);

    useEffect(() => {

        if (errorUpStage) {
            console.log(errorUpStage, 'errorUpStageerrorUpStage')
        }
    }, [errorUpStage]);

    useEffect(() => {

        if (upStageAnswer) {
            console.log(upStageAnswer, 'upStageAnswer')
            fetchGetGarageInfo()
        }
    }, [upStageAnswer]);

    useEffect(() => {
        const handleResize = () => {
            setWindowHeight(window.innerHeight);
        };

        if (windowHeight <= 580) {
             setFooterClass('garage_footer')
        }

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const handleLeftClick = () => {

        triggerVibration('heavy')

        setIsLoading(true);

        let idIndex = currentCarIndex === 0 ? garageInfo?.cars.length-1 : currentCarIndex - 1;
        fetchSelectCar(garageInfo?.cars[idIndex]?.id);

        setCurrentCarIndex((prevIndex) => (prevIndex === 0 ? garageInfo?.cars.length-1 : prevIndex - 1));
    };

    const handleRightClick = () => {
        triggerVibration('heavy')
        setIsLoading(true);
        let idIndex = currentCarIndex == garageInfo?.cars.length-1 ? 0 : currentCarIndex + 1;
        fetchSelectCar(garageInfo?.cars[idIndex]?.id);

        setCurrentCarIndex((prevIndex) => (prevIndex === garageInfo?.cars.length-1 ? 0 : prevIndex + 1));
    };

    const getHeightClassForFreeCar = () => {
        if (windowHeight >= 538 && windowHeight <= 638) {
            return 'general_page_car_img1';
        } else  if (windowHeight >= 510 && windowHeight <= 538) {
            return 'general_page_car_img2';
        }  else  if (windowHeight <= 510) {
            return 'general_page_car_img3';
        }

    };

    const getHeightClassForRound3Car = () => {
        if (windowHeight >= 538 && windowHeight <= 638) {
            return 'general_page_car_img1';
        } else  if (windowHeight >= 510 && windowHeight <= 538) {
            return 'general_page_car_img2';
        }  else  if (windowHeight <= 510) {
            return 'general_page_car_img3';
        }

    };
    const getHeightClassForRound4Car = () => {
        if (windowHeight >= 538 && windowHeight <= 638) {
            return 'general_page_car_img1';
        } else  if (windowHeight >= 510 && windowHeight <= 538) {
            return 'general_page_car_img2';
        }  else  if (windowHeight <= 510) {
            return 'general_page_car_img3';
        }

    };
    const getHeightClassForRound1Car = () => {
        if (windowHeight >= 538 && windowHeight <= 638) {
            return 'general_page_car_img1';
        } else  if (windowHeight >= 510 && windowHeight <= 538) {
            return 'general_page_car_img2';
        }  else  if (windowHeight <= 510) {
            return 'general_page_car_img3';
        }

    };
    const getHeightClassForRound2Car = () => {
        if (windowHeight >= 538 && windowHeight <= 638) {
            return 'general_page_car_img1';
        } else  if (windowHeight >= 510 && windowHeight <= 538) {
            return 'general_page_car_img2';
        }  else  if (windowHeight <= 510) {
            return 'general_page_car_img3';
        }
    };

    const handleImageLoad = () => {
        setIsLoading(false);
    };
    const handleStageClick = (stage) => {
        triggerVibration('heavy')
        setShowChangeStageModal(true)
        setPrevAndNextStageForStageModal({
            current_stage: garageInfo?.cars[currentCarIndex]?.stage,
            next_stage: stage,
            carId: garageInfo?.cars[currentCarIndex]?.id,
            price: garageInfo?.stageCosts[`stage${stage}`]
        })

        // setAuto1ActiveStage(stage)
        setIsLoading(true);

    };

    const getCarActiveImage = () => {
        let index = currentCarIndex;
        return `${process.env.REACT_APP_IMG_DOMAIN}/${garageInfo?.cars[index]?.imagePath}`;

         // if (index == 0) {
         //      return garageInfo?.cars[index]?.imagePath;
         // } else if (index == 1) {
         //     return showGarageList[index][auto1ActiveStage]?.car_img_url;
         // } else if (index == 2) {
         //     return showGarageList[index][auto2ActiveStage]?.car_img_url;
         // }  else if (index == 3) {
         //     return showGarageList[index][auto3ActiveStage]?.car_img_url;
         // }  else if (index == 4) {
         //     return showGarageList[index][auto4ActiveStage]?.car_img_url;
         // }
    }

    const getCarActiveClass = () => {
        let index = currentCarIndex;
         if (index == 0) {
              return `${showGarageList[index][0]?.car_class} ${getHeightClassForFreeCar()}`
         } else if (index == 1) {
             return `${showGarageList[index][auto1ActiveStage]?.car_class} ${getHeightClassForRound1Car()}`
         } else if (index == 2) {
             return `${showGarageList[index][auto2ActiveStage]?.car_class} ${getHeightClassForRound2Car()}`
         }  else if (index == 3) {
             return `${showGarageList[index][auto3ActiveStage]?.car_class} ${getHeightClassForRound3Car()}`
         }  else if (index == 4) {
             return `${showGarageList[index][auto4ActiveStage]?.car_class} ${getHeightClassForRound4Car()}`
         }
    }

    const getCarActiveName = () => {
        let index = currentCarIndex;
        return garageInfo?.cars[index]?.name
    }
    const getCarActivePower = () => {
        let index = currentCarIndex;
        if (garageInfo?.cars[index]?.stage == 0) {
            return '0%';
        } else if (garageInfo?.cars[index]?.stage == 1) {
            return '25%';
        } else if (garageInfo?.cars[index]?.stage == 2) {
            return '50%';
        } else if (garageInfo?.cars[index]?.stage == 3) {
            return '75%';
        } else if (garageInfo?.cars[index]?.stage == 4) {
            return '100%';
        }
    }
    const getCarActiveHP = () => {
        return `${garageInfo?.cars[currentCarIndex]?.horsePower} HP`;
    }
    const getSpotLeft = () => {
        return garageInfo?.slotAmount - garageInfo?.cars?.length;
    }

    const navigate = useNavigate();

    return (
        <>

            {(loadingGarageInfo || loading ) && (
                <LoaderComponent/>
            )}

            <div className="garage_page_wrapper" id='garage_page'>
                <div className='general_page_header'>
                    <div className='general_page_item'>
                        <div className="general_page_item_top_child">
                            <Link
                                onClick={()=>{
                                    triggerVibration('heavy')
                                }}
                                to={'/settings'}
                                className="general_page_item_top_child_icon"
                                id='general_page_item_top_child_icon1'
                            >
                                <SettingsIcon />
                            </Link>

                            <GazAmount
                                userData={userData}
                            />
                        </div>
                    </div>
                    <Link
                        onClick={()=>{
                            triggerVibration('heavy')
                        }}
                        to={'/'}
                        className="general_page_item"
                    >
                        <div className="general_page_item_logo">
                            <div className="general_page_item_logo_box">
                                <LogoIcon />
                            </div>
                            <div className="general_page_item_logo_img">
                                <img src={require('../../assets/images/logo.png')} alt="" />
                            </div>
                        </div>
                    </Link>
                    <div className="general_page_item">
                        <div className="general_page_item_top_child">
                            <Link
                                onClick={()=>{
                                    triggerVibration('heavy')
                                }}
                                to={'/balanceNfs'}
                                className="general_page_item_top_child_title_icon_wrapper"
                            >
                                <MoneyIcon />
                                <p className='general_page_item_top_child_title'>{nfsBalance}</p>
                            </Link>
                            <Link
                                onClick={()=>{
                                    triggerVibration('heavy')
                                }}
                                to={'/wallet'}
                                className='general_page_item_top_child_icon'
                                id='general_page_item_top_child_icon2'
                            >
                                <WalletIcon />
                            </Link>
                        </div>
                    </div>
                </div>
                <div className="general_page_items_wrapper">
                    <div className="general_page_item2" id='general_page_item_left'>
                        <Link
                            onClick={()=>{
                                triggerVibration('heavy')
                            }}
                            to={'/profile'}
                            className="general_page_item_child"
                        >
                            <div className="general_page_item_child_icon">
                                <ProfileIcon />
                            </div>
                            <p  className='general_page_item_child_title'>{translation?.profile}</p>
                        </Link>
                        <Link
                            onClick={()=>{
                                triggerVibration('heavy')
                            }}
                            // to={'/rating'}
                            className="general_page_item_child"
                        >
                            <div className="general_page_item_child_icon">
                                {/*<RatingIcon/>*/}
                                <div className="general_page_item_child_icon_child">
                                    <img src={require('../../assets/images/lock_img.png')} alt=""/>
                                </div>
                            </div>
                            <p className='general_page_item_child_title'>{translation?.rating}</p>
                        </Link>
                        <Link
                            onClick={()=>{
                                triggerVibration('heavy')
                            }}
                            to={'/friends'}
                            className="general_page_item_child"
                        >
                            <div className="general_page_item_child_icon">
                                <FriendsIcon />
                            </div>
                            <p className='general_page_item_child_title'>{translation?.frens}</p>
                        </Link>
                        <div to={'p2p'} className="general_page_item_child">
                            <div className="general_page_item_child_icon">
                                {/*<P2PIcon />*/}
                                <div className="general_page_item_child_icon_child">
                                    <img src={require('../../assets/images/lock_img.png')} alt=""/>
                                </div>
                            </div>
                            <p className='general_page_item_child_title'>P2P</p>
                        </div>
                        {/*<div className="general_page_item_help_btn_wrapper">*/}
                        {/*    <button className='general_page_item_help_btn'>*/}
                        {/*        <span className='general_page_item_help_btn_text'>H</span>*/}
                        {/*        <span className='general_page_item_help_btn_text'>E</span>*/}
                        {/*        <span className='general_page_item_help_btn_text'>L</span>*/}
                        {/*        <span className='general_page_item_help_btn_text'>P</span>*/}
                        {/*    </button>*/}
                        {/*</div>*/}


                    </div>
                    <div className="general_page_item2" id='general_page_item_right'>
                        <Link
                            onClick={()=>{
                                triggerVibration('heavy')
                            }}
                            to={'/carDealerShip'}
                            className="general_page_item_child"
                        >
                            <p style={{width:55}} className='general_page_item_child_title'>
                                {translation?.car_dealership}
                            </p>
                            <div className="general_page_item_child_icon">
                                <CarDealershipIcon />
                            </div>
                        </Link>
                        <div className="general_page_item_child">
                            <p className='general_page_item_child_title'>{translation?.garage}</p>
                            <div className="general_page_item_child_icon general_page_item_child_garage">
                                <GarageActiveIcon />
                            </div>
                        </div>
                        <Link
                            onClick={()=>{
                                triggerVibration('heavy')
                            }}
                            // to={'/carMarket'}
                            className="general_page_item_child"
                        >
                            <p className='general_page_item_child_title'>{translation?.car_market}</p>
                            <div className="general_page_item_child_icon">
                                {/*<CarMarketIcon/>*/}
                                <div className="general_page_item_child_icon_child">
                                    <img src={require('../../assets/images/lock_img.png')} alt=""/>
                                </div>
                            </div>
                        </Link>

                        <Link
                            onClick={()=>{
                                triggerVibration('heavy')
                            }}
                            to={'/carExhibition'}
                            className="general_page_item_child"
                        >
                            <p className='general_page_item_child_title'>{translation?.exhibition}</p>
                            <div className="general_page_item_child_icon">
                                <ExhibitionIcon/>
                                {/*<LockIcon/>*/}
                                {/*<div className="general_page_item_child_icon_child">*/}
                                {/*    <img src={require('../../assets/images/lock_img.png')} alt=""/>*/}
                                {/*</div>*/}
                            </div>
                        </Link>
                        {/*<div className="general_page_item_child_wheel_icon_wrapper">*/}
                        {/*    <div className='general_page_item_child_wheel_icon'>*/}
                        {/*        <img src={require('../../assets/images/wheel_icon2.png')} alt="" />*/}
                        {/*    </div>*/}
                        {/*</div>*/}
                    </div>
                </div>

                {/*{isLoading ? (*/}
                {/*    <Loader />*/}
                {/*) : (*/}
                {/*   */}
                {/*)}*/}
                <div style={{ width: '100%' }}>
                    <div className={getCarActiveClass()}>
                        <img
                            src={getCarActiveImage()}
                            alt=""
                            onLoad={handleImageLoad}
                        />
                        <div className="general_page_car_info_box">
                            <div className="general_page_car_info_title_box">
                                <p className="general_page_car_info_title">{getCarActiveName()}</p>
                            </div>
                            <div className="general_page_car_info_box_child">
                                <div className='general_page_car_info_box_power_info_wrapper'>
                                    <p className='general_page_car_info_box_power_info_wrapper_title'>
                                        {translation?.power}
                                    </p>
                                    <div className='general_page_car_info_box_power_info_wrapper_main_box'>
                                        <div style={{ width: getCarActivePower() }} className='general_page_car_info_box_power_info_wrapper_child'>
                                        </div>
                                    </div>
                                </div>
                                <p className="general_page_car_info_box_hp_info">{getCarActiveHP()}</p>
                            </div>
                        </div>
                    </div>
                    <div
                        className='general_page_car_spots_info_box'
                        onClick={()=>{
                            setShowSpaceListModal(true);
                        }}
                    >
                        <div className='general_page_car_spots_info_box_child'>
                            <div className='general_page_car_spots_info_box_icon'>
                                <SpotIcon />
                            </div>
                            <p className='general_page_car_spots_info_box_title'>{getSpotLeft()} {translation?.spots_left}</p>
                        </div>
                    </div>

                    <div className='general_page_car_slider_buttons_wrapper'>
                        <button className='general_page_car_slider_left_btn' onClick={handleLeftClick}>
                            <LeftIcon />
                        </button>
                        <button className='general_page_car_slider_right_btn' onClick={handleRightClick}>
                            <RightIcon />
                        </button>
                    </div>

                </div>

                <div className={footerClass.length > 0 ? footerClass : 'general_page_footer'}>
                    <div className="cars_list_info_box">

                        <Link
                            onClick={()=>{
                                triggerVibration('heavy')
                            }}
                            to={'/carlist'}
                            className="cars_list_info_box_child"
                        >
                            <div className='cars_list_info_box_icon'>
                                <CarsListIcon/>
                            </div>
                            <p className='cars_list_info_box_title'>{translation?.list_of_cars}</p>
                        </Link>

                        {currentCarIndex > 0 &&
                            <div
                                onClick={() => {
                                    triggerVibration('heavy')
                                    setInventoryModal(true)
                                }}

                                className="cars_list_info_box_child orange"
                            >
                                <div className='cars_list_info_box_icon'>
                                    <Inventory/>
                                </div>
                                <p className='cars_list_info_box_title'>{translation?.inventory}</p>
                            </div>

                        }


                    </div>
                    {currentCarIndex > 0 &&

                        <div className='cars_stages_buttons_main_wrapper'>
                            <div className="cars_stages_buttons_tabs_wrapper">

                                {Array.from({length: garageInfo?.cars[currentCarIndex]?.maxStage}).map((_, index) => {

                                    let isDisable = true;
                                    let curentStage = garageInfo?.cars[currentCarIndex]?.stage;
                                    let curentIndex = parseInt(index + 1);
                                    let activeClass = curentStage == curentIndex  ?  curentIndex == 4 ?  `active_stage${curentIndex}` : 'active_stage1' : '';

                                    if (curentIndex > curentStage) {
                                        isDisable = false;
                                    }


                                    if (curentIndex == 4) {
                                        let nextStage = 4;

                                        return (
                                            <button
                                                key={index}
                                                disabled={isDisable}
                                                className={`cars_stages_buttons_tab ${activeClass}`}
                                                onClick={() => handleStageClick('4')}
                                            >
                                                <span className="cars_stages_buttons_tab_icon">
                                                    <GrayIcon/>
                                                </span>
                                                <span className="cars_stages_buttons_tab_title">
                                                    Stage {curentIndex}
                                                </span>
                                            </button>
                                        )
                                    } else {
                                        let nextStage = curentStage + 1;

                                        return (
                                            <button
                                                key={index}
                                                disabled={isDisable}
                                                className={`cars_stages_buttons_tab ${activeClass}`}
                                                onClick={() => {
                                                    handleStageClick(curentIndex);
                                                }}
                                            >
                                                <span className="cars_stages_buttons_tab_title">
                                                     Stage {curentIndex}
                                                </span>
                                            </button>
                                        );
                                    }


                                })}




                            </div>
                            <button className="cars_stages_tuning_btn">{translation?.tuning}</button>
                        </div>
                    }

                    <button
                        className='general_page_footer_btn'
                        onClick={() => {
                            navigate(-1)
                            triggerVibration('heavy')
                        }}
                    >
                        {translation?.back_to_home}
                    </button>
                </div>
            </div>

            <UpgradeStageModal
                isActive={showChangeStageModal}
                onClose={() => {
                    setShowChangeStageModal(false)
                    triggerVibration('heavy')
                }}
                stageData={prevAndNextStageForStageModal}
                upStage={(carId, next_stage)=>{
                    triggerVibration('heavy')
                    fetchUpStage(carId, next_stage);
                    setShowChangeStageModal(false)

                }}
            />


            <SpaceListModal
                isActive={showSpaceListModal}
                onClose={()=>{
                    setShowSpaceListModal(false);
                }}
                onBy={(type)=>{
                    setBySpaceModalType(type);
                    setShowBySpaceModal(true);
                }}
            />

            <BySpaceModal
                bySpaceModalType={bySpaceModalType}
                isActive={showBySpaceModal}
                onClose={()=>{
                    setShowBySpaceModal(false)
                }}
                onCloseSuccess={()=>{
                    fetchGetGarageInfo()
                    fetchUpStage()
                    setShowBySpaceModal(false)
                }}
                noNfs={()=>{
                    setShowBySpaceModal(false)
                    setShowNoNfsModal(true)
                }}
            />

            <NoNfsModal
                isActive={showNfsModal}
                onClose={()=>{
                    setShowNoNfsModal(false)
                }}
                redirectToWallet={()=>{
                    setShowBySpaceModal(false)
                    setShowSpaceListModal(false);
                    setShowNoNfsModal(false)
                    navigate('/wallet')
                }}
            />

            <InventoryModal
                currentCar={garageInfo?.cars[currentCarIndex]}
                garageInfo={garageInfo}
                isActive={inventoryModal}
                onClose={()=>{
                    setInventoryModal(false)
                }}
                onCloseAfterUpstage={()=>{
                    setInventoryModal(false)
                    fetchGetGarageInfo()
                }}

            />


        </>
    );
}

export default GaragePage;
